<template>
  <v-container fill-height fluid class="blue lighten-4">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="indigo">
            <v-toolbar-title>Auto BOT Yod Ronaldo - Version 0.1</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="loginForm" :disabled="isDisabled" lazy-validation>
              <v-text-field prepend-icon="mdi-account" name="login" label="Login" ref="txtUsername"
                v-model="form.username" :rules="rules.id" color="indigo" type="text"
                @keyup.enter="id_enter"></v-text-field>
              <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Password" ref="txtPassword"
                v-model="form.password" :rules="rules.pw" color="indigo" type="password"
                @keyup.enter="submitLoginForm"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="indigo" :disabled="isDisabled" dark @click="submitLoginForm">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-snackbar v-model="snackbar" :bottom="y === 'bottom'" :color="login_snackbar.color" :left="x === 'left'"
        :multi-line="mode === 'multi-line'" :right="x === 'right'" :timeout="timeout" :top="y === 'top'"
        :vertical="mode === 'vertical'">
        {{ error_text }}

        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  beforeCreate() {
    if (localStorage.getItem("blog_token") != null) {
      this.$router.push("/Pincode");
    }
  },
  mounted() {
    this.$refs.txtUsername.focus();
  },
  methods: {
    id_enter() {
      if (this.form.username.length > 0) {
        this.$refs.txtPassword.focus();
      }
    },
    submitLoginForm() {
      this.isDisabled = true;
      if (this.form.username.length == 0) {
        this.isDisabled = false;
        this.$refs.txtUsername.focus();
      } else if (this.form.password.length == 0) {
        this.isDisabled = false;
        this.$refs.txtPassword.focus();
      } else {
        this.do_login(this.form.username, this.form.password);
      }
    },
    async do_login(p_username, p_password) {
      let credentials = {
        to: "login",
        method: 'login',
        username: p_username,
        password: p_password,
      };

      await this.$store.dispatch("auth/TransferData", credentials).then(
        (response) => {
          switch (response.Status) {
            case "0":
              this.isDisabled = false;
              this.$router.push("/Dashboard");
              break;
            case "1":
              this.isDisabled = false;
              this.show_error("บัญชีผู้ใช้งานถูกระงับ");
              break;
            case "2":
              this.isDisabled = false;
              this.$router.push("/Create_Pincode");
              break;
          }

          //this.show_success(response.user);
          //this.$router.push("/Dashboard");
          console.log(response);
        },
        (error) => {
          this.login_message(error);
          this.isDisabled = false;
        }
      );
    },
    show_success(nickname) {
      this.login_snackbar.color = "success";
      this.error_text = nickname + " เข้าสู่ระบบ";
      this.snackbar = true;
    },
    show_error(error_text) {
      this.login_snackbar.color = "error";
      this.error_text = error_text;
      this.snackbar = true;
    },
    login_message(msg) {
      switch (msg) {
        case "PW_INCORRECT":
          this.show_error("รหัสผ่านไม่ถูกต้อง");
          this.form.password = "";
          this.$refs.txtPassword.focus();
          break;
        case "USER_NOTFOUND":
          this.show_error("ชื่อผู้ใช้ไม่ถูกต้อง");
          this.$refs.txtUsername.focus();
          break;
      }
    },
  },
  data: () => ({
    //form
    isDisabled: false,
    form: {
      username: "",
      password: "",
      error: false,
    },
    rules: {
      id: [(val) => (val || "").length > 0 || "This field is required"],
      pw: [(val) => (val || "").length > 0 || "This field is required"],
    },
    snackbar: false,
    login_snackbar: {
      color: "error",
    },
    mode: "",
    error_text: "My timeout is set to 2000.",
    timeout: 2000,
    x: null,
    y: "top",
  }),
};
</script>

<style></style>
